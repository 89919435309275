import { useState, useEffect } from "react";
import pricetag from "../images/pricetag.png"
import foil from "../images/foil_146x204.png"
import { parse } from "papaparse";
import csvdata from "../data/Inventory_Nielsch_2021.December.02.csv"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeholderback from '../missing.jpg';
import Select from 'react-select'

function TradeBinder() {
  const [cols] = useState(2);
  const [rows] = useState(2);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [sets, setSets] = useState([]);

  const [currSets, setCurrSets] = useState([]);
  const [currRarities, setCurrRarities] = useState([]);
  const [showFoils, setShowFoils] = useState(null);

  const [filteredData, setFilteredData] = useState([]);
  // const [setOptions, setSetOptions] = useState({});

  const sortOptions = [
    "Name",
    "Price",
    "Set / collectors number",
    "Count"
  ]
  const [currSortOption, setCurrSortOption] = useState("Name");

  useEffect(() => {
    async function getData() {
      const response = await fetch(csvdata);
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder('utf-8');
      const csv = decoder.decode(result.value);

      const pResult = parse(csv, { header: true });
      setData(pResult.data);
      setFilteredData(pResult.data);

      let binderSets = [
        ...(new Set(
          Object.values(pResult.data).map(
            (card) => card["Edition"]
          )))].sort();
      setSets(binderSets);
      setCurrSets(binderSets);
    }

    getData();
  }, []);


  let style = {
    gridTemplateColumns: `repeat(${cols}, 1fr)`,
    gridTemplateRows: `repeat(${rows}, 1fr)`,
  };

  // Apply filters
  useEffect(() => {
    let fData = [...data];

    if (currSets.length > 0) {
      fData = fData.filter((card) => currSets.indexOf(card["Edition"]) > -1);
    }

    if (currRarities.length > 0) {
      fData = fData.filter((card) => currRarities.indexOf(card["Rarity"]) > -1);
    }

    if (showFoils != null) {
      fData = fData.filter((card) => (card["Foil"] === "foil") === showFoils)
    }

    // Sorting
    if (currSortOption === "Name") {
      fData.sort((cardA, cardB) =>
        cardA["Name"] > cardB["Name"] ? 1 : -1
      );
    } else if (currSortOption === "Price") {
      fData.sort((cardA, cardB) =>
        parseFloat(cardA["Price"]?.slice(1) || "0.00") > parseFloat(cardB["Price"]?.slice(1) || "0.00") ?
          -1 : 1
      );
    } else if (currSortOption === "Set / collectors number") {
      fData.sort((cardA, cardB) =>
        cardA["Edition"] === cardB["Edition"] ?
          (cardA["Card Number"] < cardB["Card Number"] ? -1 : 1)
          :
          (cardA["Edition"] < cardB["Edition"] ? -1 : 1)
      );
    } else if (currSortOption === "Count") {
      fData.sort((cardA, cardB) =>
        cardA["Count"] > cardB["Count"] ? -1 : 1
      );
    }

    setFilteredData([...fData]);
    setPage(0);
  }, [data, currSets, currRarities, showFoils, currSortOption]);

  let setOptions = sets.map((set) => { return { label: set, value: set } });
  let sortOpts = sortOptions.map((option) => { return { label: option, value: option } });

  return (
    <div className="binder-root">
      <div className="trade-binder" style={style} key={`page-${page}-of-${filteredData.length}-${currSortOption}`}>
        {
          data.length > 0 ?
            filteredData
              .slice(page * cols * rows, (page + 1) * cols * rows)
              .map((card) => <TradeCard card={card} key={card["Name"] + card["Edition"]} />)
            :
            <div>Loading data...</div>
        }
      </div>
      <div className="page-buttons">
        <button
          onClick={() => setPage(Math.max(0, page - 1))}
          disabled={page === 0}
        >
          Previous page
        </button>
        <div>Page {page + 1}</div>
        <button
          onClick={() => setPage(Math.min(page + 1, Math.ceil(filteredData.length / (cols * rows)) - 1))}
          disabled={page >= Math.ceil(filteredData.length / (cols * rows)) - 1}
        >
          Next page
        </button>
      </div>
      <div className="selectors" key={"setname" + sets.length}>
        <Select
          key={"set-select" + sets.length}
          className="set-selector"
          options={setOptions}
          isSearchable="true"
          defaultValue={currSets}
          isMulti={true}
          onChange={(e) => setCurrSets(e.map((set) => set.value))}
        />
        <Select
          key={"option-select"}
          className="sort-selector"
          options={sortOpts}
          defaultValue={currSortOption}
          onChange={(e) => setCurrSortOption(e.value)}
        />
        <button
          onClick={
            () => {
              if (showFoils == null) {
                setShowFoils(true);
              } else if (showFoils) {
                setShowFoils(false);
              } else {
                setShowFoils(null);
              }
            }
          }
        >
          Show {showFoils == null ? "all" : showFoils ? "foils" : "non-foils"}
        </button>
      </div>
    </div>
  )
}

function getPriceString(prices, isFoil, curr = 'eur') {
  const valutaSymbol = {
    'eur': '€',
    'usd': '$',
    'tix': '',
  }

  if (!(curr in prices) || !prices[curr]) {
    if (!("usd" in prices)) {
      return "?"
    }
    curr = "usd"
  }

  if (isFoil) {
    curr += '_foil'
  }

  return `${valutaSymbol[curr]}${prices[curr]}`
}

function TradeCard(props) {
  const { card } = props;
  const pCardPrice = "?";
  // console.log(props, cardName, cardSet, cardNumber);
  const [imageURL, setImageURL] = useState("");
  const [cardPrices, setCardPrices] = useState([pCardPrice]);

  const cardName = card["Name"];
  const cardNumber = card["Card Number"];
  const cardSet = card["Edition"];
  const cardCount = card["Count"];
  const isFoil = card["Foil"] === "foil";

  let prices = [];

  useEffect(() => {
    const getImageURL = async () => {
      let url = `https://api.scryfall.com/cards/random?q=${cardName} cn:${cardNumber}&include_extras=true`;
      const result = fetch(url)
        .then(res => res.json())
        .then(result => {
          if (result["object"] === "error") {
            console.log(`Could not find card ${cardName} (${cardNumber} - ${cardSet})`)
            console.log(result);
            setImageURL(placeholderback);
            return;
          }

          setCardPrices(result["prices"]);

          let imageURI = "card_faces" in result && "image_uris" in result["card_faces"][0] ? result["card_faces"][0]["image_uris"]["small"] : result["image_uris"]["small"];
          setImageURL(imageURI);
        },
          error => {
            console.log(error);
          }
        );
    }

    getImageURL();
  }, []);

  let st = {
    "animationDuration": (Math.random() + 0.5) + "s"
  }

  return (
    <div className="trade-card" key={`${cardName}-${cardSet}`}>
      <img src={placeholderback} alt={""} />
      <LazyLoadImage
        src={imageURL}
        alt={`${cardName} (${cardSet})`}
        placeholderSrc={placeholderback}
        // effect="blur"
        // width={"192.2px"}
        // height={"272.55px"}
        style={st}
        visibleByDefault={true}
        className={"actual-image"}
      />
      {isFoil ? <img className="foil" src={foil} alt="foil-cover" /> : <div />}
      <div className="sticker">
        {/* <div className="sticker"> */}
        <div className="stickerHolder">
          <img src={pricetag} alt="" />
          <span className="price">{cardCount > 1 ? `${cardCount}x ` : ""}{getPriceString(cardPrices)}</span>
        </div>
      </div>
    </div>
  )
}

export default TradeBinder