import { useState, useEffect } from 'react';
import Select from 'react-select'
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Dymo from 'dymojs';
import { Canvg } from 'canvg';
import SetData from '../data/sets.json';
import MTGSetLabelXML from '../data/mtg-set-icons.dymo';

// Print the provided XMLs on the Dymo 4XL
// TODO: Printer selection
function PrintLabels(labelXMLS) {
    console.log(labelXMLS[0]);
    let p = new Dymo();
    // p.getPrinters().then((info) => console.log(info));
    // p.getStatus().then((info) => console.log(info));
    // console.log(p.apiUrl);
    // Print with delay to not overwhelm the printer
    labelXMLS.forEach((labelXML, index) => setTimeout(() => p.print('DYMO LabelWriter 4XL', labelXML), 500 * index));
    toast(`Sent ${labelXMLS.length} print jobs!`);
}

function LabelPrinter() {
    const [selectedSets, setSelectedSets] = useState([]);
    const [baseLabelXML, setBaseLabelXML] = useState('');
    const [iconSVGsAsPNGURLS, setIconSVGsAsPNGURLS] = useState([]);
    const [labelXMLS, setLabelXMLS] = useState([]);
    const [labelPreviewURLS, setLabelPreviewURLS] = useState([]);

    // The set types to filter for
    let setTypes = [
        "core",
        "expansion",
        "masters",
        "draft_innovation",
        "funny",
    ]

    // The sets for which icons should be selectable
    const sets = SetData.data.filter((set) => setTypes.includes(set.set_type)).map((set) => {
        return {
            value: set.icon_svg_uri,
            label: set.name
        }
    });

    // Get the base label XML
    useEffect(() => {
        fetch(MTGSetLabelXML)
            .then(r => r.text())
            .then(text => {
                setBaseLabelXML(text);
            });
    }, []);

    // Convert the selected sets' svgs to PNG dataurls to use as images and xml data
    useEffect(() => {
        // Create a canvas to draw the SVG
        const cv = document.createElement("canvas");
        const ctx = cv.getContext('2d');

        // Set the canvas to the svg, render the canvas, and convert the rendering to a url
        let dataURLPromises = selectedSets.map((selectedSet) =>
            Canvg.from(ctx, selectedSet.value).then((v) => v.render().then(() => cv.toDataURL())));

        Promise.all(dataURLPromises).then((dataURLS) => setIconSVGsAsPNGURLS(dataURLS));
    }, [selectedSets]);

    // Update the label XMLS when sets are selected
    useEffect(() => {
        const regex = /<Data>([0-z+/=]*?)<\/Data>/g;
        let labelXMLS = [];
        // Two icons per label
        for (let i = 0; i < iconSVGsAsPNGURLS.length; i += 2) {
            let n = 0;
            let labelXML = baseLabelXML.replace(
                regex, function () {
                    // With an uneven number of sets, repeat the last selected set
                    let labelImageAsURL = iconSVGsAsPNGURLS[i + n] || iconSVGsAsPNGURLS[i];
                    n++;
                    // The image URL contains a header which needs to be removed
                    return `<Data>${labelImageAsURL.split(',', 2)[1]}</Data>`
                });
            labelXMLS.push(labelXML);
        }
        setLabelXMLS(labelXMLS);
    }, [baseLabelXML, iconSVGsAsPNGURLS])

    // Render a preview of the labels
    useEffect(() => {
        let dymo = new Dymo();
        const labelPreviewPromises = labelXMLS.map((labelXML) => dymo.renderLabel(labelXML).then((imageURL) => imageURL));
        Promise.all(labelPreviewPromises).then((imageURLS) => setLabelPreviewURLS(imageURLS));
    }, [labelXMLS]);

    return (
        <div className="labelprinter">
            <h1>Select sets</h1>
            <Select
                key="set-select"
                className="setSelector"
                options={sets}
                isSearchable="true"
                onChange={(sets) => setSelectedSets(sets)}
                isMulti={true}
                closeMenuOnSelect={false}
            />
            <div>
                <h1>Icons</h1>
                {
                    iconSVGsAsPNGURLS.map((imageURL, i) =>
                        <img
                            className="set-svg-icon"
                            key={selectedSets[i]?.label}
                            src={imageURL}
                            alt={`${selectedSets[i]?.label} icon`}
                        />
                    )
                }
            </div>
            <h1>Labels</h1>
            <div id="label-container">
                {
                    labelPreviewURLS.map((imageURL, i) =>
                        <img
                            className="label-preview"
                            alt={`Preview for sets ${i * 2 + 1} and ${i * 2 + 2}`}
                            src={`data:image/png;base64,${imageURL.slice(1, -1)}`}
                            key={`${i}-label`}
                        />
                    )
                }
            </div>

            <br />
            <Button className="print-button" onClick={() => PrintLabels(labelXMLS)}>Print Labels</Button>
        </div>
    );
}

export default LabelPrinter