import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import Select from 'react-select'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cardBack from '../missing.jpg';

function CardCounter() {
    const [allSetsOptionsList, setAllSetsOptionsList] = useState([]);
    const [allSets, setAllSets] = useState({});
    const [allCards, setAllCards] = useState({});
    const [allCounts, setAllCounts] = useState({});
    const [currentSetCode, setCurrentSetCode] = useState('');
    const [setTypes] = useState([
        "core",
        "expansion",
        "masters",
        "masterpiece",
        "draft_innovation",
    ]);

    // Get the metadata for all sets
    useEffect(() => {
        fetch(`https://api.scryfall.com/sets`)
            .then(res => res.json())
            .then((result) => {
                // Filter the sets for regular releases
                let sets = result.data.filter((set) => { return setTypes.indexOf(set.set_type) >= 0 && set.card_count > 0 });

                // Store metadata for sets
                let allSets = Object.fromEntries(sets.map((set) => [set.code, set]));

                // Create an options list for the selector
                let allSetsOptionsList = sets.map((set) => (
                    { label: set.name, value: set.code }
                ));

                // Create a store for the card counts
                let allCounts = Object.fromEntries(Object.keys(allSets).map((setCode) => [setCode, null]));

                setAllSets(allSets);
                setAllSetsOptionsList(allSetsOptionsList);
                setAllCounts(allCounts);
            }, (error) => {
                toast.error("Something went wrong while loading data from Scryfall. Please reload the page and try again.")
            }
            );
    }, [setTypes]);

    // Get the card data for a set url
    function getSetCards(url, setCards = {}) {
        return new Promise(
            (resolve, reject) => fetch(url)
                .then(res => res.json())
                .then(
                    (res) => {
                        setCards = { ...setCards, ...Object.fromEntries(res.data.map((card) => [card.collector_number, card])) };
                        if (res.has_more) {
                            getSetCards(res.next_page, setCards).then(resolve).catch(reject)
                        } else {
                            resolve(setCards);
                        }
                    },
                    (error) => {
                        toast.error("Failed fetching cards from Scryfall. Reload and try again.")
                    }
                )
        )
    }

    // Select a set
    function selectSet(setCode) {
        if (!(setCode in allCards)) {
            getSetCards(allSets[setCode].search_uri)
                .then(setCards => {
                    setCurrentSetCode(setCode);
                    setAllCounts({ ...allCounts, [setCode]: Object.fromEntries(Object.keys(setCards).map((cardId) => [cardId, [0, 0]])) });
                    setAllCards({ ...allCards, [setCode]: setCards });
                });
        } else {
            setCurrentSetCode(setCode);
        }
    }

    function exportSelection(e) {
        let deckList = "";
        let sideboard = "//Sideboard\n";
        // handle this automatically
        let totalValue = {
            usd: 0,
            usd_foil: 0,
            usd_etched: 0,
            eur: 0,
            eur_foil: 0,
            tix: 0
        };

        for (var setCode in allCounts) {
            const setCount = allCounts[setCode];
            if (setCount == null) {
                continue;
            }
            for (let cn in setCount) {
                if (setCount[cn] === 0) {
                    continue;
                }
                let card = allCards[setCode][cn];
                if (setCount[cn][0] > 0)
                    deckList += `${setCount[cn][0]} ${card.name} (${setCode}) ${cn}\n`

                if (setCount[cn][1] > 0)
                    sideboard += `${setCount[cn][1]} ${card.name} (${setCode}) ${cn}\n`

                for (let currency in card.prices) {
                    totalValue[currency] += parseFloat(card.prices[currency] || 0) * (setCount[cn][0] + setCount[cn][1]);
                }
            }
        }

        let fulllist = `${deckList}\n${sideboard}`;

        console.log(fulllist);
        console.log(totalValue);

        // Copy the decklist to the clipboard
        const el = document.createElement('textarea');
        el.value = fulllist;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        toast.success('📝 Card list copied to clipboard!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    }

    // Update the count of a card
    function updateCount(setCode, cardID, change, sideboard = false) {
        // Copy the original dictionary
        let countCopy = { ...allCounts };
        let index = sideboard ? 1 : 0;

        // Edit the copy, clamping at zero
        countCopy[setCode][cardID][index] += change;
        countCopy[setCode][cardID][index] = Math.max(0, countCopy[setCode][cardID][index]);

        // Set the state
        setAllCounts(countCopy);
    }

    return (
        <div className="card-counter-main">
            <div className="count-nav">
                <Select
                    key="set-select"
                    className="set-selector"
                    options={allSetsOptionsList}
                    isSearchable="true"
                    defaultValue={allSetsOptionsList[0]}
                    onChange={(option) => selectSet(option.value)}
                />
                <Button variant="primary" onClick={exportSelection}>Export</Button>
            </div>
            <SetList
                key={currentSetCode}
                counts={allCounts[currentSetCode]}
                cardFunc={updateCount}
                setCards={allCards[currentSetCode]}
            />
        </div>
    );
}

function SetList(props) {
    const { counts, setCards, cardFunc } = props;

    // Scroll to the top when a new set is selected
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Return a placeholder if no cards are present
    if (!setCards || Object.keys(setCards).length === 0) {
        return (
            <div className="no-set-placeholder">Choose a set to display ⤴️</div>
        );
    }

    // Otherwise, return the set list
    return (
        <div className="setlist">
            {
                Object.keys(setCards).map(cn => (
                    <SetCard
                        key={setCards[cn].collector_number}
                        cardFunc={cardFunc}
                        count={counts[setCards[cn].collector_number]}
                        cardData={setCards[cn]}
                    />
                ))
            }
        </div>
    );
}

function SetCard(props) {
    const { count, cardFunc, cardData } = props;

    function handleButton(n, sideboard = false) {
        cardFunc(cardData.set, cardData.collector_number, n, sideboard);
    }

    // Build price strings
    let counts = `${count[0]} (${count[1]} SB)`;
    let countPrice = "-"
    let singlePrice = "Price data not available";
    if (cardData.prices.eur != null) {
        countPrice = `€${(cardData.prices.eur * (count[0] + count[1])).toFixed(2)}`;
        singlePrice = `€${cardData.prices.eur}`;
    } else if (cardData.prices.usd != null) {
        countPrice = `$${(cardData.prices.usd * (count[0] + count[1])).toFixed(2)}`;
        singlePrice = `$${cardData.prices.usd}`;
    }

    return (
        <div className="cardbox">
            <div className="column">
                <LazyLoadImage
                    src={`${cardData.uri}?format=image&version=large`}
                    alt={`${cardData.name} (${cardData.set_name})`}
                    placeholderSrc={cardBack}
                    effect="blur"
                    width={cardBack.width}
                    height={cardBack.height}
                />
                <button className="btn-plusfour btn-small btn" onClick={() => handleButton(4)}>+4</button>
                <button className="sb btn-plusfour-sb btn-small btn" onClick={() => handleButton(4, true)}><span>+4 SB</span></button>
                <button className="btn-plusone btn" onClick={() => handleButton(1)}>+1</button>
                <button className="sb btn-plusone btn" onClick={() => handleButton(1, true)}><span>+1 SB</span></button>
                <button className="btn-minusone btn" onClick={() => handleButton(-1)}>-1</button>
                <button className="sb btn-minusone btn" onClick={() => handleButton(-1, true)}><span>-1 SB</span></button>
                <button className="btn-minusfour btn-small btn" onClick={() => handleButton(-4)}>-4</button>
                <button className="sb btn-minusfour-sb btn-small btn" onClick={() => handleButton(-4, true)}><span>-4 SB</span></button>
            </div>
            <div className="column card-info">
                <span className="card-name">{cardData.name}</span>
                <span className="card-count">{counts}</span>
                <span className="card-price">{`${countPrice} / ${singlePrice}`}</span>
            </div>
        </div>
    );
}

export default CardCounter;