import logo from './logo.png';
import React from 'react';
import { Button } from 'react-bootstrap';
import {
  Link,
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

import { clientID, sheetID, apiKey, scope } from './gapiData'

import TradeBinder from './components/tradebinder';
import LabelPrinter from './components/labelprinter';
import CardCounter from './components/cardcounter';
import TournamentReporter from './components/tournamentreporter';

// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as HML } from './images/hml.svg';
import { ReactComponent as NED } from './images/9ed.svg';
import { ReactComponent as WTH } from './images/wth.svg';
import { ReactComponent as RTR } from './images/rtr.svg';
import { ReactComponent as JUD } from './images/jud.svg';

class App extends React.Component {
  render() {
    return (
      <Router>
        <div id="router-root">
          <div className="main-nav">
            <Link to="/" id='nav-logo'>
              <img
                alt=""
                src={logo}
                className="d-inline-block align-top"
              >
              </img>
            </Link>
            {/* <Navbar.Brand>Umori</Navbar.Brand> */}
            <Link to="/"><HML /><span>Home</span></Link>
            <Link to="/CardCounter"><NED /><span>Counter</span></Link>
            <Link to="/TournamentReporter"><JUD /><span>Tournament Reporter</span></Link>
            <Link to="/TradeBinder"><WTH /><span>Trade Binder</span></Link>
            {/* <Link to="/Picker">Picker</Link> */}
            <Link to="/LabelPrinter"><RTR /><span>Label Printer</span></Link>
          </div>

          <Switch>
            <Route path="/CardCounter">
              <CardCounter />
            </Route>
            <Route path="/Picker">
              <Picker />
            </Route>
            <Route path="/TournamentReporter">
              <TournamentReporter />
            </Route>
            <Route path="/TradeBinder">
              <TradeBinder />
            </Route>
            <Route path="/LabelPrinter">
              <LabelPrinter />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <ToastContainer />
        </div>
        <div className='footer'>
          Parts of Umori.me are unofficial Fan Content permitted under the Fan Content Policy.
          Not approved/endorsed by Wizards.
          Portions of the materials used are property of Wizards of the Coast. ©Wizards of the Coast LLC.
          <br />
          Umori makes extensive use of the <a href="https://scryfall.com/docs/api">Scryfall API</a> (💜) for card imagery, set icons and (indirectly) price data.
          Absolutely no guarantee is made for any price information. See stores for final prices and details.
          <br />
          All other content © {new Date().getFullYear()} Umori.me
          <br />
          <address>For any issues, questions or tips, message me on <a href="https://twitter.com/Nielsch">Twitter</a>.</address>
        </div>
      </Router>
    )
  }
}

class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <h1><dfn>Umori</dfn>is a<br />Magic: The Gathering collection and draft tracking tool.</h1>
        <span>
          The <i>Counter</i> can be used to easily count cards in sets, to track a collection or a limited deck.
          <br />
          The <i>Draft</i> is a tool for tracking your tournament results (and exporting it to Google Sheets).
          <br />
          In <i>Trade Binder</i>, you can showcase a collection of cards for trading or bragging purposes.
          <br />
          Finally, the <i>Label Printer</i> is a tool to print set icon labels on a very specific label size for a very specific printer.
        </span>
      </div>
    )
  }
}


class Picker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      gapiLoaded: false
    }
  }

  componentDidMount() {
    window.gapi.load(
      'client:auth2',
      {
        callback: function () {
          this.setState({ gapiLoaded: true });
          this.initClient();
        }.bind(this),
        onerror: function () {
          // Handle loading error.
          alert('gapi.client failed to load!');
        },
        timeout: 5000, // 5 seconds.
        ontimeout: function () {
          // Handle timeout.
          alert('gapi.client could not load in a timely manner!');
        }
      }
    );

    window.gapi.load(
      'picker',
      this.onPickerApiLoad
    );
  }

  initClient() {
    window.gapi.client.init({
      'apiKey': apiKey,
      'clientId': clientID,
      'scope': scope,
      'discoveryDocs': ['https://sheets.googleapis.com/$discovery/rest?version=v4']
    })
  }

  onFileSelect(url) {
    console.log(url);
  }

  onPickerApiLoad() {
    console.log("loaded picker api")
  }

  logOut = () => {
    console.log("Start of the logout process");
    if (!this.state.loggedIn) {
      toast.success("User is already logged out!");
    } else {
      window.gapi.auth2.getAuthInstance().signOut().then(
        (response) => {
          console.log(response);
          const loggedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();
          this.setState({ loggedIn: loggedIn });
          toast.success(`User is ${loggedIn ? '' : 'not '}logged in!`)
        },
        (error) => {
          console.log(error);
        }
      );
    }
    console.log("End of the logout process");
  }

  logIn = () => {
    console.log("Start of the login process");
    if (this.state.loggedIn) {
      toast.success("User is already logged in!");
    } else {
      window.gapi.auth2.getAuthInstance().signIn().then(
        (response) => {
          console.log(response);
          const loggedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();
          this.setState({ loggedIn: loggedIn });
          toast.success(`User is ${loggedIn ? '' : 'not '}logged in!`)
        },
        (error) => {
          console.log(error);
        }
      );
    }
    console.log("End of the login process");
  }

  doPicker = () => {
    var loggedIn = this.state.loggedIn;
    console.log("User logged in:", loggedIn);
    if (!loggedIn) {
      this.logIn();
      return;
    }

    var token = window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token

    var picker = new window.gapi.picker.api.PickerBuilder()
      .addView(window.gapi.picker.api.ViewId.SPREADSHEETS)
      .setOAuthToken(token)
      // .setAppId(clientID)
      // .setDeveloperKey(apiKey)
      .setCallback(this.onFileSelect)
      .build();
    picker.setVisible(true);
    return;
  }

  render() {
    return (
      <div>
        <Button onClick={this.doPicker.bind(this)}>Picker</Button>
        <Button onClick={this.logIn}>Log in</Button>
        <Button onClick={this.logOut}>Log out</Button>
        <ToastContainer />
      </div>
    );
  }
}

export default App;
