import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Form, Button } from "react-bootstrap";
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import DatePicker from "react-datepicker";

import { clientID, sheetID, apiKey, scope } from '../gapiData'
import { ReactComponent as SymbolW } from '../images/mtg-symbols/W.svg'
import { ReactComponent as SymbolU } from '../images/mtg-symbols/U.svg'
import { ReactComponent as SymbolB } from '../images/mtg-symbols/B.svg'
import { ReactComponent as SymbolR } from '../images/mtg-symbols/R.svg'
import { ReactComponent as SymbolG } from '../images/mtg-symbols/G.svg'
import { ReactComponent as SymbolC } from '../images/mtg-symbols/C.svg'

function TournamentReporter() {
    const [date, setDate] = useState(new Date());
    const [set, setSet] = useState('');
    const [setOptions, setSetOptions] = useState({});
    const [deck, setDeck] = useState('');
    const [notes, setNotes] = useState('');
    const [record, setRecord] = useState([
        [null, null, 0, 0, 0],
        [null, null, 0, 0, 0],
        [null, null, 0, 0, 0]
    ]);
    const [oppNames, setOppNames] = useState([
        "Pepijn",
        "Hilko",
        "Bram",
        "Jorian",
        "Patrick",
        "Peter",
        "Jorim",
        "Dave"
    ]);
    // const [nRounds, setNRounds] = useState(3);

    const [setTypes] = useState(
        [
            "core",
            "expansion",
            "masters",
            "masterpiece",
            "draft_innovation",
        ]
    );

    const [gApiLoaded, setGApiLoaded] = useState(false);

    // Load the set list and Google API
    useEffect(() => {
        fetch(`https://api.scryfall.com/sets`)
            .then(res => res.json())
            .then(
                (result) => {
                    let sets = result.data.filter((set) => { return setTypes.indexOf(set.set_type) >= 0 && set.card_count > 0 });
                    let setOptions = sets.map((set) => (
                        { label: set.name, value: set.code.toUpperCase() }
                    ));

                    setSetOptions(setOptions);
                }
            );

        window.gapi.load('client:auth2', {
            callback: function () {
                setGApiLoaded(false);
                initClient();
            },
            onerror: function () {
                // Handle loading error.
                alert('gapi.client failed to load!');
            },
            timeout: 5000, // 5 seconds.
            ontimeout: function () {
                // Handle timeout.
                alert('gapi.client could not load in a timely manner!');
            }
        });

        window.gapi.load('picker', onPickerApiLoad);
    }, [setTypes]);

    // Google API Stuff
    function initClient() {
        window.gapi.client.init({
            'apiKey': apiKey,
            'clientId': clientID,
            'scope': scope,
            'discoveryDocs': ['https://sheets.googleapis.com/$discovery/rest?version=v4']
        });

        window.gapi.auth2.authorize({
            client_id: clientID,
            scope: scope
        })
    }

    function onPickerApiLoad() {
        console.log("loaded picker!");
    }

    // function pickerCallback(data) {
    //     console.log("picker picked");
    //     console.log(data);
    // }

    // State setting callbacks
    function recordFunction(round, wld, change) {
        let r = record;
        r[round][wld + 2] = Math.max(0, record[round][wld + 2] + change);

        setRecord([...r]);
    }

    function addRound() {
        let r = record;
        r.push([null, null, 0, 0, 0]);
        setRecord([...r]);
    }

    function opponentFunction(e) {
        let o = oppNames;
        if (oppNames.indexOf(e.value) < 0) {
            o.push(e.value);
        }

        let r = record;
        r[e.round][0] = e.value;

        setOppNames(o);
        setRecord([...r]);
    }

    function setDeckColors(round, colors) {
        // Parse colors
        let deckstring = "WUBRGC".split("").filter((c, i) => colors[i]).join("");

        if (round === -1) {
            // Own deck
            setDeck(deckstring);
        } else {
            // Opponent deck
            let r = record;
            r[round][1] = deckstring;
            setRecord([...r]);
        }
    }

    function exportResult(e) {
        //Collect the data
        let sheetsData = [
            date.toLocaleDateString("en-UK"),
            0,
            set,
            deck,
            ...record.flat(),
            "Insert Scryfall url here",
            notes
        ];

        // The function that exports the data
        const exportToSheets = function () {
            console.log("User logged in:", window.gapi.auth2.getAuthInstance().isSignedIn.get());

            //Set up the request
            const params = {
                spreadsheetId: sheetID,
                range: 'Table',
                valueInputOption: 'RAW',
                insertDataOption: 'INSERT_ROWS'
            };

            const valueRangeBody = {
                'majorDimension': 'ROWS',
                'values': [sheetsData]
            };

            let request = window.gapi.client.sheets.spreadsheets.values.append(params, valueRangeBody);
            const runReq = request.then(
                function (response) {
                    console.log(response);
                },
                function (reason) {
                    console.error(reason.result.error.message);
                }
            );

            toast.promise(
                runReq,
                {
                    pending: {
                        render() {
                            return "Pending..."
                        }
                    },
                    success: {
                        render({ data }) {
                            return "Exported to Google Sheets!"
                        }
                    },
                    error: {
                        render({ data }) {
                            return `Failed to export: ${data}`
                        }
                    }
                }
            )
        };

        //Make sure the user is logged in
        let isSignedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();

        if (!isSignedIn) {
            window.gapi.auth2.getAuthInstance().signIn().then(
                function (response) {
                    console.log(response);
                    console.log("Signed in!");
                    exportToSheets();
                },
                function (reason) {
                    console.log(reason);
                    toast.error("To export, you'll have to grant permission")
                    return;
                }
            );
        } else {
            console.log("User is already logged in!")
            exportToSheets();
        }
    }

    return (
        <div className="reporter-root">
            <h1>Tournament Reporter</h1>
            <div className="tournament-tracker">
                <div className="tracker-round header">
                    <div>
                        <DatePicker
                            className="tournament-date-picker"
                            selected={date}
                            onChange={(date) => setDate(date)}
                        />
                        <Select
                            key="set-select"
                            className="set-selector"
                            options={setOptions}
                            isSearchable="true"
                            defaultValue={setOptions}
                            onChange={e => setSet(e.value)}
                        />
                    </div>
                    <ColorPicker setDeckColors={setDeckColors} round={-1} />
                    <Form.Control type="text" placeholder="Notes" onChange={e => setNotes(e.target.value)} />
                </div>

                {
                    record.map((recordRow, i) =>
                        <div className="tracker-round" key={`tr-${i}`}>
                            <div className="round-header">
                                <span className="round-title">Round {i + 1}</span>
                                <OpponentPicker round={i} opp_names={oppNames} opp_func={opponentFunction} />
                            </div>
                            <ColorPicker setDeckColors={setDeckColors} round={i} />
                            <div className="record-picker">
                                <RecordTicker key={`r${i}w`} record={record} round={i} recordFunc={recordFunction} wld={0} />
                                <RecordTicker key={`r${i}l`} record={record} round={i} recordFunc={recordFunction} wld={1} />
                                <RecordTicker key={`r${i}d`} record={record} round={i} recordFunc={recordFunction} wld={2} />
                            </div>
                        </div>
                    )
                }
            </div>
            <Button onClick={addRound}>Add round</Button>
            <Button onClick={exportResult} disabled={gApiLoaded}>Export</Button>
        </div>
    )
}

function ColorPicker(props) {
    const [colors, setColors] = useState([false, false, false, false, false, false])
    const { setDeckColors, round } = props;

    function toggleColors(i) {
        let newColors = colors.slice();
        newColors[i] = !newColors[i];
        setColors(newColors);
        setDeckColors(round, newColors);
    }

    return (
        <div className="mtg-color-picker">
            <SymbolW style={{ opacity: colors[0] ? "100%" : "20%" }} alt="White" onClick={() => toggleColors(0)} />
            <SymbolU style={{ opacity: colors[1] ? "100%" : "20%" }} alt="Blue" onClick={() => toggleColors(1)} />
            <SymbolB style={{ opacity: colors[2] ? "100%" : "20%" }} alt="Black" onClick={() => toggleColors(2)} />
            <SymbolR style={{ opacity: colors[3] ? "100%" : "20%" }} alt="Red" onClick={() => toggleColors(3)} />
            <SymbolG style={{ opacity: colors[4] ? "100%" : "20%" }} alt="Green" onClick={() => toggleColors(4)} />
            <SymbolC style={{ opacity: colors[5] ? "100%" : "20%" }} alt="Colorless" onClick={() => toggleColors(5)} />
        </div>
    )
}

function OpponentPicker(props) {
    const { round, opp_func, opp_names } = props;

    function fixName(name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    let opp_opts = opp_names.map((name) => ({ label: name, value: name, round: round }));

    return (
        <Creatable
            key={`opp-select-r${round}`}
            className="opp-selector"
            options={opp_opts}
            isSearchable="true"
            getNewOptionData={(i, e) => { return { label: fixName(i), value: fixName(i), round: round } }}
            onChange={opp_func}
        />
    )
}

function RecordTicker(props) {
    const { round, record, recordFunc, wld } = props;

    return (
        <div className="record-ticker">
            <span>{record[round][wld + 2]}</span>
            <button className="pls" onClick={() => recordFunc(round, wld, 1)} />
            <button className="min" onClick={() => recordFunc(round, wld, -1)} />
        </div>
    );
}

export default TournamentReporter;